dmx.Component('if', {

    attributes: {
        condition: {
            type: Boolean,
            default: false
        }
    },

    events: {
        show: Event,
        hide: Event
    },

    render: function(node) {
        this.nodes = [];
        this.template = document.createDocumentFragment();
        while (this.$node.firstChild) {
            this.template.appendChild(this.$node.firstChild);
        }
        this.update({});
    },

    update: function(props) {
        if (this.props.condition != props.condition) {
            if (!this._willUpdate) {
                this._willUpdate = true;
                //requestAnimationFrame(() => this._update());
                // Want to do DOM updates on animation frame for less DOM updates
                // but then components and data from the children comes very late
                // for now on nextTick to do it as fast as posible
                dmx.nextTick(this._update, this);
            }
        }
    },

    _update: function() {
        this._willUpdate = false;

        if (this.props.condition) {
            this._render();
        } else {
            this._destroy();
        }
    },

    _render: function() {
        var template = this.template.cloneNode(true);
        this.nodes = Array.prototype.slice.call(template.childNodes);
        this.$node.appendChild(template);
        this.$parse();
        dmx.requestUpdate();
        dmx.nextTick(function() {
            this.dispatchEvent('show');
        }, this);
    },

    _destroy: function() {
        this.bindings = {};
        this.nodes.splice(0).forEach(function(node) {
            var event = new Event('remove', { cancelable: true });
            if (node.dispatchEvent(event)) {
                dmx.dom.remove(node);
            }
        });
        this.children.splice(0).forEach(function(child) {
            child.$destroy();
        });
        dmx.nextTick(function() {
            this.dispatchEvent('hide');
        }, this);
    }

});
